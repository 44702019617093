var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"close":function($event){return _vm.$emit('close', true)},"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.isOut)?[_vm._v(" Ushbu mijoz("+_vm._s(_vm.$route.query.pin)+")ni qora ro'yhatdan chiqarish uchun asos fayl yuklang ")]:_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('JShShIR ni kiriting'),"label-for":"pin"}},[_c('validation-provider',{attrs:{"name":"pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##############'),expression:"'##############'"}],attrs:{"id":"pin","placeholder":"00000000000000","state":errors.length > 3 ? false : null,"name":"pin"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Izoh'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","state":errors.length > 3 ? false : null,"name":"name","placeholder":"Izoh"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"passport-upload"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"text-center"},[(
            _vm.form.enter_document_url &&
              _vm.form.enter_document_url.slice(-3) == 'pdf'
          )?[_c('div',{on:{"click":function($event){return _vm.openPdf(_vm.storageUrl + _vm.form.enter_document_url)}}},[_c('pdf',{staticClass:"img-uploaded",attrs:{"src":_vm.storageUrl + _vm.form.enter_document_url,"height":"162","width":"162"}})],1)]:(_vm.form.enter_document_url)?[_c('img',{staticClass:"img-uploaded",attrs:{"src":_vm.storageUrl + _vm.form.enter_document_url,"height":"162","width":"162"}})]:[_c('img',{staticClass:"img-placeholder",attrs:{"src":require("@/assets/images/svg/fileuploader-placeholder.svg"),"alt":"image","width":"162"},on:{"click":function($event){return _vm.$refs.addressPassport.click()}}})]],2),_c('div',{staticClass:"text-center"},[_c('input',{ref:"addressPassport",staticClass:"d-none",attrs:{"accept":"image/jpeg, image/jpg, image/png, application/pdf","type":"file"},on:{"change":function (e) { return _vm.onChangeFile(e); }}}),_c('span',{staticClass:"upload-label",on:{"click":function($event){return _vm.$refs.addressPassport.click()}}},[(_vm.form.out_document_url)?[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm.$t("Qora ro'yhatdan chiqarish uchun asos"))+" "),_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"12"}})]:(_vm.form.enter_document_url)?[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm.$t("Qora ro'yhatga kiritish uchun asos"))+" "),_c('feather-icon',{attrs:{"icon":"Trash2Icon","size":"12"}})]:[_c('feather-icon',{attrs:{"icon":"PlusSquareIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm.isOut || _vm.form.status == 2 ? _vm.$t("Qora ro'yhatdan chiqarish uchun asos") : _vm.$t("Qora ro'yhatga kiritish uchun asos"))+" ")]],2)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }