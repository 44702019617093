<template>
  <div>
    <save
      ref="saveForm"
      :is-out="isOut"
      @onSuccess="getItems"
      @close="closeModal"
    />
    <b-tabs v-model="filterModel.statusValue">
      <b-tab
        v-for="tab in settingTabs"
        :key="tab.id"
        :title="tab.name"
        @click="onChange(tab)"
      />
    </b-tabs>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :has-show="false"
        :has-delete="false"
        model="suggestion"
        :filter="filterModel"
        @getItems="getItems"
        @blacklistOut="blacklistOut"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          {{ props }}
        </template>
      </good-table-column-search>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      roles: [],
      isOut: false,
      filterModel: {
        per_page: 50,
        firstname: null,
        lastname: null,
        address: null,
        status: 1,
        statusValue: 0,
        relations: 'client|createdUser|oldContract',
      },
      items: [],
      settingTabs: [
        {
          id: 0,
          value: 1,
          name: "Qora ro'yhatga kiritilganlar",
        },
        {
          id: 1,
          value: 2,
          name: "Qora ro'yhatdan chiqarilganlar",
        },
        {
          id: 2,
          value: null,
          name: 'Hammasi',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({}),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Фамилия'),
          field: item => (item.old_contract
            ? item.old_contract.lastname
            : item.client
              ? item.client.lastname
              : ''),
          // field: 'client.lastname',
          filterField: 'client.lastname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
          showField: true,
        },
        {
          label: this.$t('Имя'),
          field: item => (item.old_contract
            ? item.old_contract.firstname
            : item.client
              ? item.client.firstname
              : ''),
          // field: 'client.firstname',
          filterField: 'client.firstname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
          showField: true,
        },
        {
          label: this.$t('Отчества'),
          field: item => (item.old_contract
            ? item.old_contract.middlename
            : item.client
              ? item.client.middlename
              : ''),
          // field: 'client.middlename',
          showField: true,
        },
        {
          label: this.$t('Telefon'),
          field: item => (item.old_contract
            ? item.old_contract.phone
            : item.client
              ? item.client.phone
              : ''),
        },
        {
          label: this.$t('Pasport'),
          field: this.passportField,
          filterField: 'passport',
          // filterOptions: {
          //   enabled: true,
          //   placeholder: '',
          // },
        },
        {
          label: this.$t('Jshshir'),
          field: 'pin',
          filterField: 'pin',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Izoh'),
          field: 'comment',
          isTooltip: true,
        },
        {
          label: 'Asos hujjat',
          field: 'enter_document_url',
          isUrl: true,
        },
        {
          label: 'Chiqarishga asos hujjat',
          field: 'out_document_url',
          isUrl: true,
        },
        {
          label: this.$t('Кто зарегистрирован'),
          field: 'created_user.name',
        },
        {
          label: this.$t('Дата'),
          field: 'created_at',
          isDate: true,
        },
        {
          label: this.$t('Действия'),
          field: 'action',
          isBlackList: this.filterModel.status == 1,
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getBlacklist',
      destroyAction: 'resource/destroyBlacklistItem',
    }),
    setField(row, field) {
      console.log(row, field)
      if (row.client && row.client.lastname) {
        return row.client.lastname
      }
      if (row.old_contract) {
        return row.old_contract.passport
      }
      return ''
    },
    onChange(tab) {
      this.filterModel.statusValue = tab.id
      this.filterModel.status = tab.value
      if (tab.id == 1) {
        this.filterModel.relations.concat('|outedUser')
      } else {
        this.filterModel.relations.concat('client|createdUser')
      }
      this.getItems()
    },
    blacklistOut(item) {
      this.isOut = true
      this.$refs.saveForm.visible = true
      this.changeQuery('pin', item.pin)
    },
    closeModal() {
      this.isOut = false
      this.changeQuery('pin', '')
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(
        res => {
          this.items = res.data
        },
      )
      this.loading = false
    },
    passportField(row) {
      if (row.old_contract) {
        return row.old_contract.passport
      }
      return row.client ? row.client.doc_series + row.client.doc_number : ''
    },
    pinField(row) {
      return row.client.pin
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') {
        return nameuz
      }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id)
              .then(res => {
                showToast('success', this.$t('Успешно удалено'))
                this.getItems()
              })
              .catch(() => {
                showToast('success', this.$t('Успешно удалено'))
              })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === filterName) {
          item.filterOptions.filterDropdownItems = res.data.data.map(i => {
            i.value = i.id
            i.text = i.name
            return i
          })
        }
        // if (item.field === 'status') {
        //   item.filterOptions.filterDropdownItems = [
        //     { value: 1, text: this.$t('Активный') },
        //     { value: 0, text: this.$t('Неактивный') },
        //   ]
        // }
      })
    },
  },
}
</script>

<style scoped></style>
