<template>
  <b-modal
    v-model="visible"
    size="lg"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @close="$emit('close', true)"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <template v-if="isOut">
        Ushbu mijoz({{ $route.query.pin }})ni qora ro'yhatdan chiqarish uchun
        asos fayl yuklang
      </template>
      <b-form
        v-else
        class="auth-login-form mt-2"
      >
        <b-row>
          <b-col cols="4">
            <b-form-group
              :label="$t('JShShIR ni kiriting')"
              label-for="pin"
            >
              <validation-provider
                #default="{ errors }"
                name="pin"
                rules="required"
              >
                <b-form-input
                  id="pin"
                  v-model="form.pin"
                  v-mask="'##############'"
                  placeholder="00000000000000"
                  :state="errors.length > 3 ? false : null"
                  name="pin"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="9">
            <b-form-group
              :label="$t('Izoh')"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-textarea
                  id="address"
                  v-model="form.comment"
                  :state="errors.length > 3 ? false : null"
                  name="name"
                  placeholder="Izoh"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-col class="passport-upload">
        <div
          v-loading="loading"
          class="text-center"
        >
          <template
            v-if="
              form.enter_document_url &&
                form.enter_document_url.slice(-3) == 'pdf'
            "
          >
            <div @click="openPdf(storageUrl + form.enter_document_url)">
              <pdf
                :src="storageUrl + form.enter_document_url"
                height="162"
                width="162"
                class="img-uploaded"
              />
            </div>
          </template>
          <template v-else-if="form.enter_document_url">
            <img
              :src="storageUrl + form.enter_document_url"
              height="162"
              width="162"
              class="img-uploaded"
            >
          </template>
          <template v-else>
            <img
              src="@/assets/images/svg/fileuploader-placeholder.svg"
              class="img-placeholder"
              alt="image"
              width="162"
              @click="$refs.addressPassport.click()"
            >
          </template>
        </div>
        <div class="text-center">
          <input
            ref="addressPassport"
            accept="image/jpeg, image/jpg, image/png, application/pdf"
            type="file"
            class="d-none"
            @change="(e) => onChangeFile(e)"
          >
          <span
            class="upload-label"
            @click="$refs.addressPassport.click()"
          >

            <template v-if="form.out_document_url">
              <feather-icon
                icon="CheckIcon"
                size="12"
              />
              {{ $t("Qora ro'yhatdan chiqarish uchun asos") }}
              <feather-icon
                icon="Trash2Icon"
                size="12"
              />
            </template>
            <template v-else-if="form.enter_document_url">
              <feather-icon
                icon="CheckIcon"
                size="12"
              />
              {{ $t("Qora ro'yhatga kiritish uchun asos") }}
              <feather-icon
                icon="Trash2Icon"
                size="12"
              />
            </template>
            <template v-else>
              <feather-icon
                icon="PlusSquareIcon"
                size="12"
              />
              {{
                isOut || form.status == 2
                  ? $t("Qora ro'yhatdan chiqarish uchun asos")
                  : $t("Qora ro'yhatga kiritish uchun asos")
              }}
            </template>
          </span>
        </div>
      </b-col>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isOut: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      form: {
        id: null,
        pins: [],
        pin: null,
        enter_document_url: null,
        out_document_url: null,
        status: null,
        comment: null,
      },
      loading: false,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isOutForm() {
      return this.isOut
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
        }, 200)
      }
    },
    isOutForm(newVal) {
      if (this.isOut) {
        this.form.pin = this.$route.query.pin
        this.title = this.$t("Qora ro'yhatdan chiqarish")
      } else {
        this.title = this.$t("Qora ro'yhatga qo'shish")
      }
    },
  },
  mounted() {
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    openPdf(link) {
      window.open(link, '_blank')
    },
    async onChangeFile(event) {
      if (event.target.files[0].size <= 21360000) {
        this.loading = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', 'blacklist-documents')
        this.fileUpload(fileData)
          .then(res => {
            if (this.isOut || this.form.status == 2) {
              this.form.out_document_url = res.data.path
            }
            this.form.enter_document_url = res.data.path
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 20 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.form.pins = [this.form.pin]
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.changeQuery('pin', '')
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            if (err.status === 422) {
              this.$refs.form.setErrors(err.data.errors)
            }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.getBlacklistItem(item.id).then(res => {
        this.form.id = res.data.id
        this.form.pin = res.data.pin
        this.form.comment = res.data.comment
        this.form.enter_document_url = res.data.enter_document_url
        this.form.out_document_url = res.data.out_document_url
        this.form.status = res.data.status
        this.visible = true
      })
    },
    method(data) {
      if (this.form.id) {
        return this.updateBlacklist(data)
      }
      if (this.isOut) {
        return this.blacklistOut(data)
      }
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    oncheckboxChanged() {
      if (this.isAnotherProduct) {
        this.form.category_id = null
      } else {
        this.form.other_product_name = null
      }
    },
    //
    ...mapActions({
      storeItem: 'resource/storeBlacklist',
      blacklistOut: 'resource/blacklistOut',
      getBlacklistItem: 'resource/getBlacklistItem',
      updateBlacklist: 'resource/updateBlacklist',
      fileUpload: 'client/fileUpload',
      // updateItem: 'resource/updateSuggestion',
    }),
  },
}
</script>

<style scoped></style>
